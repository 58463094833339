/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: 1, // boolean: loading screen on first visit
  themeToggle: 1, // boolean: allow user to change theme in header
  showProfilePhoto: 1, // boolean: determines if profile or graphic is shown
};

//Home Page
const greeting = {
  profilePhoto: "assets/1709330876712.jpg", //doesnt work for some reason. go to Greeting.js line 56
  title: "Hello World 👋!",
  footer: "Copyright © Diego Araujo ", // footer + currentYear + "."
  // logo_name: "Mateo-Wallace",
  full_name: "Diego Araujo",
  subTitle: "Full Stack Developer, IT Tech. Forever Learning and Loving Every Minute of It!",
  resumeLink:
    "https://drive.google.com/file/d/1bWOSvX9H_SPmixipKrrC8d6LS7LI7zrx/view?usp=sharing",
};

// Error 404, Not Found
const errorPageInfo = {
  titleHighlight: "404",
  title: "Not Found",
  subTitle:
    "Sorry about that. It seems like you've made it to a secret page",
  image: 0, //for graphic make 0, for image make myImage.png
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/DiegoAR98",
  linkedin: "https://www.linkedin.com/in/diego-a-aa39402b7/",
  gmail: "diegoaraujolive@hotmail.com",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for web and mobile applications",
        "⚡ Building responsive website front end using ReactJS and Bootstrap",
        "⚡ Creating application backend in Node & Express",
        "⚡ Managing databases with DynamoDB, MongoDB, MySQL, and Sequelize",
        "⚡ Structuring APIs using REST & GraphQL",
        "⚡ Implementing Progressive Web Applications (PWA) and Server-Side APIs",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: { color: "#E34F26" },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: { color: "#1572B6" },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: { color: "#7952B3" },
        },
        {
          skillName: "Tailwind CSS",
          fontAwesomeClassname: "simple-icons:tailwindcss",
          style: { color: "#06B6D4" },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "devicon-csharp",
          style: { color: "#239120" },
        },
        {
          skillName: ".NET",
          fontAwesomeClassname: "skill-icons:dotnet",
          style: { color: "#512BD4" },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "vscode-icons:file-type-node",
          style: { color: "#339933" },
        },
        {
          skillName: "Express.js",
          fontAwesomeClassname: "simple-icons:express",
          style: { color: "#ffffff" },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: { color: "#61DAFB" },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: { color: "#47A248" },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: { color: "#4479A1" },
        },
        {
          skillName: "Sequelize",
          fontAwesomeClassname: "simple-icons:sequelize",
          style: { color: "#52B0E7" },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: { color: "#DE33A6" },
        },
        {
          skillName: "Apollo GraphQL",
          fontAwesomeClassname: "simple-icons:apollographql",
          style: { color: "#311C87" },
        },
        {
          skillName: "API",
          fontAwesomeClassname: "mdi:api",
          style: { color: "#FF6E00" },
        },
        {
          skillName: "JWT Token",
          fontAwesomeClassname: "logos:jwt-icon",
          style: { color: "#000000" },
        },
        {
          skillName: "Progressive Web Apps",
          fontAwesomeClassname: "simple-icons:pwa",
          style: { color: "#5A0FC8" },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: { color: "#E94E32" },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: { color: "#CB3837" },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: { color: "#0865A6" },
        },
        {
          skillName: "JSON",
          fontAwesomeClassname: "vscode-icons:file-type-light-json",
          style: { color: "fbc02d" },
        },
        {
          skillName: "Markdown",
          fontAwesomeClassname: "simple-icons:markdown",
          style: { color: "#ffffff" },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: { color: "#3776AB" },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "logos:flask",
          style: { color: "#ffffff" },
        },
        {
          skillName: "SQLAlchemy",
          fontAwesomeClassname: "devicon:sqlalchemy",
          style: { color: "#d34a36" },
        },
        {
          skillName: "Jinja2",
          fontAwesomeClassname: "simple-icons:jinja",
          style: { color: "#B41717" },
        },
        {
          skillName: "DynamoDB",
          fontAwesomeClassname: "simple-icons:amazondynamodb",
          style: { color: "#4053D6" },
        },
        {
          skillName: "EC2",
          fontAwesomeClassname: "simple-icons:amazonec2",
          style: { color: "#FF9900" },
        },
        {
          skillName: "S3",
          fontAwesomeClassname: "simple-icons:amazons3",
          style: { color: "#569A31" },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
          style: { color: "#336791" }
        },
        {
          skillName: ".NET Core",
          fontAwesomeClassname: "devicon:dotnetcore",
          style: { color: "#512BD4" }
        },
      ],

      
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#00C7B7",
          },
        },
        {
          skillName: "Render",
          fontAwesomeClassname: "simple-icons:render",
          style: {
            color: "#0078FF",
          },
        },
        {
          skillName: "GitHub Pages",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#5b77ef",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "skill-icons:aws-dark",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};


const majorEducation = {
  data: [
    {
      title: "University of Central Florida",
      subtitle: "Certificate in Full Stack Web Development",
      logo_path: "ucf-logo.png",
      alt_name: "ucf logo",
      duration: "Oct 2023 - Apr 2024",
      descriptions: [
        "⚡ A six month intensive bootcamp course of 240 contact hours.",
        "⚡ Learned to work on multiple group projects integrating Git workflow.",
        "⚡ Gained the skill to become a MERN stack developer, creating various deployed websites with databases.",
      ],
      website_link:
        "https://badgr.com/public/assertions/epuoKGOpS76WCHgkeESjNw",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "M0001: MongoDB Basics",
    //   subtitle: "MongoDB University",
    //   logo_path: "mongo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
    //   alt_name: "MongoDB University",
    //   color_code: "#47A048",
    // },
    // {
    //   title: "A300: Atlas Security",
    //   subtitle: "MongoDB University",
    //   logo_path: "mongo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
    //   alt_name: "MongoDB University",
    //   color_code: "#47A048",
    // },
    {
      title: "Dell Client Foundations",
      subtitle: "ESCLID02555",
      logo_path: "dell_logo.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1Kzf85RZhe2fGHjKbsWQ4lc9lRthSNAfv/view?usp=sharing",
      alt_name: "Dell",
      color_code: "#fffbf3",
    },
    {
      title: "Generative AI for Everyone",
      subtitle: "DeepLearning.AI",
      logo_path: "deeplearningai.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/NHHHAGVE8XCW",
      alt_name: "DeepLearning",
      color_code: "#fffbf3",
    },
    {
      title: "Getting Started with Python",
      subtitle: "University of Michigan",
      logo_path: "michigan.png",
      certificate_link:
        "https://courses.edx.org/certificates/b549fba849014d5a810cca8598d0b17a",
      alt_name: "Pythonforeveryone",
      color_code: "#3776AB",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Full Stack Web Developer, IT Technician at UDT",
  description:
    "I am currently employed as an IT Technician at UDT and am seeking remote internships and job opportunities. I am also continuously engaged in personal projects.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "IT Technician",
          company: "UNITED DATA TECHNOLOGY INC",
          company_url: "https://udtonline.com/",
          logo_path: "UDTLOGO.png",
          duration: "Nov 2022 - Present",
          location: "Orlando, Fl",
          description: `Proficiently diagnosing and repairing hardware issues in numerous laptops, including models from Dell, HP, Lenovo, and Chromebooks, for leading school districts across Florida.`,
          color: "#117888",
        },
        {
          title: "Full Stack Web Developer",
          company: "Freelance",
          company_url: "https://github.com/DiegoAR98",
          logo_path: "DABLUE.png",
          duration: "Oct 2023 - Present",
          location: "Remote",
          description: `I handle all aspects of website creation from design to functionality and maintenance. My expertise includes HTML, CSS, JavaScript, React for front-end development, and Node.js, Python, MySQL, and MongoDB for back-end services. I build responsive and secure websites tailored to meet client needs, focusing on optimal user experience and client satisfaction.`,
          color: "#e3405f",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "GitHub Student Developer",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Nov 2022 - Present",
          location: "Remote",
          description:
            "Contribute to Open Source Community and Open Source Project.",
          color: "#040f26",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
  "My projects utilize a wide array of cutting-edge technological tools. I have extensive experience in developing backend projects with NodeJS and frontend projects using React. Here are some examples of my work.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    description:
      "Feel free to reach out to me using the contact details below. I'll do my best to respond as quickly as possible!",
  },
};

const projects = {
  data: [
    {
      name: "JavaScript Code Quiz Game",
      url: "https://github.com/DiegoAR98/Code-Quiz",
      deployedSite: "https://diegoar98.github.io/Code-Quiz/",
      image: "codequiz.png",
      alt_name: "codequiz",
      description:
        "JS code Quiz Game, a dynamic, interactive application designed to both challenge and enhance your understanding of JavaScript.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "AbbyWeb",
      url: "https://github.com/DiegoAR98/AbbyWeb",
      deployedSite: "https://abbyweb-6fabb2c0052a.herokuapp.com/",
      image: "abbyweb.png",
      alt_name: "Abbyweb",
      description:
        "AbbyWeb is a full-stack web application built using ASP.NET Core and Entity Framework Core. This project demonstrates the use of Razor Pages for creating a dynamic web application with a PostgreSQL database, deployed on Heroku.",
        languages: [
          {
            "name": "C#",
            "iconifyClass": "devicon-csharp"
          },
          {
            "name": "JavaScript",
            "iconifyClass": "logos:javascript"
          },
          {
            "name": "CSS3",
            "iconifyClass": "vscode-icons:file-type-css"
          },
          {
            "name": ".NET Core",
            "iconifyClass": "devicon:dotnetcore"
          },
          {
            "name": "PostgreSQL",
            "iconifyClass": "devicon:postgresql-wordmark"
          },
          {
            "name": "Bootstrap",
            "iconifyClass": "logos:bootstrap"
          },
          {
            "name": "SQL Server",
            "iconifyClass": "vscode-icons:file-type-sql"
          },
          {
            "name": "Heroku",
            "iconifyClass": "skill-icons:heroku"
          },
        ],
    },
    {
      name: "Deep Thoughts",
      url: "https://github.com/DiegoAR98/aws-thought",
      deployedSite: "https://aws-thought-b4ca7b5aa7e7.herokuapp.com/",
      image: "deep-aws.png",
      alt_name: "Deep Thoughts",
      description:
        "Deep Thoughts is an application that allows users to post their thoughts and images. This project leverages AWS services for storage, database, and computing using DynamoDB, Express.js, React, and Node.js. The application is deployed on Heroku for production.",
        languages: [
          {
            "name": "React",
            "iconifyClass": "vscode-icons:file-type-reactjs"
          },
          {
            "name": "CSS3",
            "iconifyClass": "vscode-icons:file-type-css"
          },
          {
            "name": "Express",
            "iconifyClass": "skill-icons:expressjs-dark"
          },
          {
            "name": "NodeJS",
            "iconifyClass": "vscode-icons:file-type-node"
          },
          {
            "name": "JavaScript",
            "iconifyClass": "logos-javascript",
          },
          {
            "name": "DynamoDB",
            "iconifyClass": "logos-aws-dynamodb"
          },
          {
            "name": "AWS",
            "iconifyClass": "skill-icons:aws-dark"
          },
          {
            "name": "Heroku",
            "iconifyClass": "skill-icons:heroku"
          },
        ],
    },
    {
      name: "Python Newsfeed",
      url: "https://github.com/DiegoAR98/python-newsfeed",
      deployedSite: "https://python-newsfeed-a-45a11065b1d6.herokuapp.com/",
      image: "python-news.png",
      alt_name: "Python-news",
      description:
        "Python Newsfeed is a web application built with Flask and SQLAlchemy, providing users with the ability to create, read, update, and delete posts. The application supports user authentication, post upvotes, and comment functionality.",
        languages: [
          {
            name: "Python",
            iconifyClass: "logos:python",
            style: { color: "#3776AB" },
          },
          {
            name: "Flask",
            iconifyClass: "logos:flask",
            style: { color: "#ffffff" },
          },
          {
            name: "SQLAlchemy",
            iconifyClass: "devicon:sqlalchemy",
            style: { color: "#d34a36" },
          },
          {
            name: "Jinja2",
            iconifyClass: "simple-icons:jinja",
            style: { color: "#B41717" },
          },
          {
            "name": "Heroku",
            "iconifyClass": "skill-icons:heroku"
          },
        ],
    },
    {
    name: "CatWorx Badge Maker",
    url: "https://github.com/DiegoAR98/CatWorx",
    deployedSite: "https://github.com/DiegoAR98/CatWorx",
    image: "CatWork.png",
    alt_name: "CatWorx",
    description:
      "A simple C# application to automate employee badge creation.",
      languages: [
        {
          name: "C#",
          iconifyClass: "devicon-csharp", // Please ensure this is the correct class name in iconify
        },
      ],
  },
    {
      name: "ArtisanConnect",
      url: "https://github.com/DiegoAR98/ArtisanConnect.DA",
      deployedSite: "https://artisanconnect-8fe5.onrender.com/",
      image: "artisanconnect.jpg",
      alt_name: "ArtisanConnect",
      description:
        "E-commerce platform that allows users to either purchase products or sell their own, specifically designed to support local farmers.",
        languages: [
          {
            name: "Tailwind CSS",
            iconifyClass: "logos-tailwindcss-icon", // Please ensure this is the correct class name in iconify
          },
          {
            name: "React",
            iconifyClass: "vscode-icons:file-type-reactjs", // Please ensure this is the correct class name in iconify
          },
          {
            name: "CSS3",
            iconifyClass: "vscode-icons:file-type-css", // Please ensure this is the correct class name in iconify
          },
          {
            name: "Express",
            iconifyClass: "skill-icons:expressjs-dark",
          },
          {
            name: "NodeJS",
            iconifyClass: "vscode-icons:file-type-node", // Same as previously listed, since Node.js is used for both front and back end
          },
          {
            name: "Apollo GraphQL",
            iconifyClass: "logos-apollostack", // Please ensure this is the correct class name in iconify
          },
          {
            name: "JWT",
            iconifyClass: "logos-jwt-icon", // Please ensure this is the correct class name in iconify
          },
          {
            name: "MongoDB",
            iconifyClass: "vscode-icons:file-type-mongo", // Please ensure this is the correct class name in iconify
          },
        ],
    },
    {
      name: "Student Project Organizer",
      url: "https://github.com/DiegoAR98/Student-Project-Organizer",
      deployedSite: "https://student-project-organizer-f32e9a6cf079.herokuapp.com/",
      image: "spo.jpg",
      alt_name: "studentprojectorganizer",
      description:
        "Comprehensive tool designed to help students manage their academic projects with efficiency and ease. This application offers functionalities to assign tasks, set deadlines, track progress, and much more.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "vscode-icons:file-type-node", // Same as previously listed, since Node.js is used for both front and back end
        },
        {
          name: "dotenv",
          iconifyClass: "vscode-icons:file-type-dotenv", // Assuming there's an icon for dotenv
        },
        {
          name: "Express",
          iconifyClass: "skill-icons:expressjs-dark",
        },
        {
          name: "mysql2",
          iconifyClass: "logos-mysql", // Using the MySQL icon for mysql2
        },
        {
          name: "sequelize",
          iconifyClass: "logos-sequelize", // Assuming there's an icon for Sequelize
        },
        {
          name: "Tailwind CSS",
          iconifyClass: "logos-tailwindcss-icon", // Please ensure this is the correct class name in iconify
        },
      ],
    },
    {
      name: "Password-Generator",
      url: "https://github.com/DiegoAR98/Password-Generator",
      deployedSite: "https://diegoar98.github.io/Password-Generator/",
      image: "passwordgenerator.png",
      alt_name: "Password generator",
      description:
        "A simple yet powerful application that enables users to create strong and secure random passwords based on a set of criteria that they select.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "National Parks: A Quintet of Natural Wonders",
      url: "https://github.com/DiegoAR98/Project-1",
      deployedSite: "https://diegoar98.github.io/Project-1/",
      image: "nationalparks.png",
      alt_name: "NationalParks",
      description:
        "National Parks: A Quintet of Natural Wonders is a web app for exploring and planning trips to the top 5 US national parks.",
        languages: [
          {
            name: "HTML5",
            iconifyClass: "vscode-icons:file-type-html",
          },
          {
            name: "CSS3",
            iconifyClass: "vscode-icons:file-type-css",
          },
          {
            name: "JavaScript",
            iconifyClass: "logos-javascript",
          },
          {
            name: "National Parks API",
            iconifyClass: "mdi-pine-tree", // mdi-pine-tree is an example, you'll need to find a suitable icon
          },
          // Assuming there's no specific icon for your Weather API, you could use a generic weather-related icon.
          {
            name: "Weather API",
            iconifyClass: "wi-day-sunny", // wi-day-sunny is from the Weather Icons pack, you'll need to find a suitable icon
          },
        ],
        
    },
    {
      name: "Connect Immigration Group",
      url: "https://connectimmigrationcorp.com/",
      deployedSite: "https://connectimmigrationcorp.com/",
      image: "cig.png",
      alt_name: "Cig",
      description:
        "A mobile-first frontend application tailored exclusively for a consulting office, designed to be responsive across various devices.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Google Books Search",
      url: "https://github.com/DiegoAR98/Book-Search-Engine",
      deployedSite: "https://book-search-engine-v9bj.onrender.com/",
      image: "book-search.png",
      alt_name: "Deployed site for Google Books Search",
      description:
        "MERN Stack search engine that allows user to create an account, search for books, and save books to a database.",
      languages: [
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb-icon",
        },
        {
          name: "Express",
          iconifyClass: "skill-icons:expressjs-dark",
        },
        {
          name: "NodeJS",
          iconifyClass: "vscode-icons:file-type-node",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "GraphQL",
          iconifyClass: "logos-graphql",
        },
      ],
    },
    {
      name: "Work Day Scheduler",
      url: "https://github.com/DiegoAR98/Work-Day-Scheduler",
      deployedSite: "https://diegoar98.github.io/Work-Day-Scheduler/",
      image: "workday.jpg",
      alt_name: "workdayschedule",
      description:
        "Daily Planner is a simple, intuitive, and effective tool designed for personal and professional time management.",
        languages: [
          {
            name: "HTML",
            iconifyClass: "vscode-icons:file-type-html",
          },
          {
            name: "CSS",
            iconifyClass: "vscode-icons:file-type-css",
          },
          {
            name: "JavaScript",
            iconifyClass: "logos-javascript",
          },
          {
            name: "jQuery",
            iconifyClass: "simple-icons:jquery",
          },
          {
            name: "Bootstrap",
            iconifyClass: "logos-bootstrap",
          },
        ],
        
    },
    {
      name: "Note Taker",
      url: "https://github.com/DiegoAR98/Note-Taker",
      deployedSite: "https://note-taker-daraujo-e02c8f2153aa.herokuapp.com/",
      image: "note-taker.png",
      alt_name: "Deployed site for note taker",
      description:
        "Allows a user to create notes, read created notes, update existing notes, and delete notes.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "vscode-icons:file-type-node",
        },
        {
          name: "Express",
          iconifyClass: "skill-icons:expressjs-dark",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
    {
      name: "Weather Dashboard",
      url: "https://github.com/DiegoAR98/Weather-Dashboard",
      deployedSite: "https://diegoar98.github.io/Weather-Dashboard/",
      image: "weather-boy.jpg",
      alt_name: "Deployed site for weather boy",
      description:
        "Weather app that allows the user to type in a city and see the weather for the next 5 days. Makes use of API in order to get weather data.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Bootstrap",
          iconifyClass: "logos-bootstrap",
        },
      ],
    },
    {
      name: "CSS Cheat Sheet",
      url: "https://github.com/DiegoAR98/CSS-Snippet-Cheatsheet",
      deployedSite: "https://diegoar98.github.io/CSS-Snippet-Cheatsheet/",
      image: "css-cheatsheet.png",
      alt_name: "Deployed site for css cheat sheet",
      description:
        "It uses advanced CSS in order to make a study guide esque site for CSS notes.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  errorPageInfo,
  socialMediaLinks,
  skills,
  majorEducation,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
